<template>

	<layout :include_header="true">

		<template v-slot:header-center>

			<main_nav />

		</template>

		<template v-slot:header-end>

			<support_link />

		</template>

		<template v-slot:main-body>
			
			<div class="w-100 h-100 d-flex align-items-center mx-auto">

				<div class="h-100 d-flex flex-column align-items-center justify-content-center overflow-hidden border-end p-4" style="flex: 0 0 500px;">
					
					<div class="w-100 h-75 d-flex flex-column align-items-center justify-content-center">

						<div class="setup-chat border rounded-top p-4 overflow-auto mx-auto w-100" ref="scrollContainer" style="max-width: 600px; height: calc( 100% - 90px );">
							
							<div>						
								
								<chat_message :content="'👋 Hey again, ' + first_name + '! I\'m going to help get your next site setup. The first thing we\'ll do is create a brief which will act as our source of truth for the project requirements. I find it works well to do a first draft and then send it across to your client for editing and approval. <br><br>Let\'s start with the basics... what\'s your clients company called?'" type="1" author="1" />

								<chat_message v-if="site.name" :content="site.name" type="2" author="4" />

								<chat_message v-if="site.name" :content="'Great! Now, what will the domain name be when the site goes live?'" type="1" author="1" />

								<chat_message v-if="site.settings && site.settings['url']" :content="site.settings['url']" type="2" author="4" />

								<chat_message v-if="active_step == 'url' && url && error && error.errors && error.errors['url']" :content="error.errors['url'][0]" :type="3" author="1" />

								<chat_message v-if="site.settings && site.settings['url']" :content="'Perfect! Please hang tight for a minute. I\'m going to attempt to crawl this website and gather information about ' + site.name + '.'" type="1" author="1" :include_countdown="include_countdown" :countdown="300" />

								<chat_message v-if="site.settings && ( active_step == 'contact' || site.settings['contact_complete'] )" :content="'Now, we need to collate ' + site.name + '\'s contact details so we can include them on the site. Please could you fill in any missing information? Let me know when you\'re ready to move to the next step.'" type="1" author="1" />

								<chat_message v-if="site.settings && site.settings['contact_complete']" content="Next step" type="2" author="4" />

								<chat_message v-if="site.settings && site.settings['contact_complete']" :content="'Cool! Can you provide URL\'s for ' + site.name + '\'s social media profiles?'" type="1" author="1" />

								<chat_message v-if="site.settings && site.settings['social_complete']" content="Next step" type="2" author="4" />

								<chat_message v-if="site.settings && site.settings['social_complete']" :content="'Perfect! It\'d be great if you could help me gain an undertanding of who ' + site.name + ' are. This will help me make better decisions as we create the site. First off, tell me a little bit about the companies history.'" type="1" author="1" />

								<chat_message v-if="site.settings && site.settings['history_complete']" content="Next step" type="2" author="4" />

								<chat_message v-if="site.settings && site.settings['history_complete']" :content="'Tell me more about why ' + site.name + ' do what they do. What\'s their mission?'" type="1" author="1" />

								<chat_message v-if="site.settings && site.settings['mission_complete']" content="Next step" type="2" author="4" />

								<chat_message v-if="site.settings && site.settings['mission_complete']" :content="'Now, tell me what ' + site.name + ' stands for. What are their core values?'" type="1" author="1" />

								<chat_message v-if="site.settings && site.settings['values_complete']" content="Next step" type="2" author="4" />

								<chat_message v-if="site.settings && site.settings['values_complete']" :content="'Now, can you tell me who their target audience is?'" type="1" author="1" />

								<chat_message v-if="site.settings && site.settings['audience_complete']" content="Next step" type="2" author="4" />

								<chat_message v-if="site.settings && site.settings['audience_complete']" :content="'Great, can you tell me who ' + site.name + ' work with and what they do / did for them?'" type="1" author="1" />

								<chat_message v-if="site.settings && site.settings['clients_complete']" content="Next step" type="2" author="4" />

								<chat_message v-if="site.settings && site.settings['clients_complete']" :content="'Finally, can you tell me who the core team members are?'" type="1" author="1" />

								<chat_message v-if="site.settings && site.settings['team_complete']" content="Next step" type="2" author="4" />

								<chat_message v-if="site.settings && site.settings['team_complete']" :content="'This is great. I feel like I\'ve gained some real insight into who ' + site.name + ' are. Now it\'s time to learn what they do. What products and services do they provide?'" type="1" author="1" />

								<chat_message v-if="site.settings && site.settings['services_complete']" content="Next step" type="2" author="4" />

								<chat_message v-if="site.settings && site.settings['services_complete']" :content="'Now I know what ' + site.name + ' do, it\'d be great to understand how they do it differently. What makes them stand out from the crowd? What are their unique selling points?'" type="1" author="1" />

								<chat_message v-if="site.settings && site.settings['usps_complete']" content="Next step" type="2" author="4" />

								<chat_message v-if="site.settings && site.settings['usps_complete']" :content="'Great! We now know who ' + site.name + ' are, what they do and how they do it differently. Just a few more things to cover. Is there any custom functionality that needs to be included on the site?'" type="1" author="1" />

								<chat_message v-if="site.settings && site.settings['features_complete']" content="Next step" type="2" author="4" />

								<chat_message v-if="site.settings && site.settings['features_complete']" :content="'Nearly there, I promise! Would you like to include any third party plugins on the site? Let me know and I can make sure Pablo automatically installs them when he builds the site.'" type="1" author="1" />

								<chat_message v-if="site.settings && site.settings['features_complete'] && !site.is_built && !test_site.is_built" :content="'While you look for plugins, I just need to set a few bits up. You\'ll be able to move to the next step shortly! I\'ll send you an email when I\'ve finished my tasks, so don\'t feel like you need to wait around.'" type="1" author="1" :include_countdown="true" :countdown="600" />

								<chat_message v-if="site.settings && site.settings['plugins_complete']" content="Next step" type="2" author="4" />

								<chat_message v-if="site.settings && site.settings['plugins_complete'] && site.is_built" :content="'Last, but by no means least, it\'s time to create the sitemap. I\'ve taken a run at it but please feel free to add, edit and organise the pages as you see fit. Let me know when you\'re ready to move on.'"  type="1" author="1" />

								<chat_message v-if="site.settings && site.settings['sitemap_complete']" content="Next step" type="2" author="4" />
								
							</div>

						</div>

						<div class="setup-chat-actions border rounded-bottom border-top-0 overflow-hidden bg-white mx-auto w-100" style="max-width: 600px; height: 90px;">
							<form @submit.prevent="submit" class="d-flex align-items-center h-100">
								<div class="h-100 flex-grow-1">
									
									<form_control
										v-if="active_step == 'client_name'"
										type="text"
										name="client_name"
										placeholder="Enter your clients company name"
										:required="true"
										:error="error"
										v-model="client_name"
										group_class="mb-0 h-100"
									/>

									<form_control
										v-if="active_step == 'url'"
										type="text"
										name="url"
										placeholder="Enter live URL"
										:required="true"
										:error="error"
										v-model="url"
										group_class="mb-0 h-100"
									/>

									<div v-else-if="!['client_name', 'url', 'complete', 'sitemap', 'plugins'].includes( active_step )" class="p-3">
										<btn_submit 
											label="Next step" 
											icon="check"  
											:icon_after="true" 
											:loading="loading"
										/>
									</div>

									<div v-else-if="['plugins'].includes( active_step ) && (test_site.is_built || site.is_built)" class="p-3">
										<btn_submit 
											label="Next step" 
											icon="check"  
											:icon_after="true" 
											:loading="loading"
										/>
									</div>

									<div v-else-if="['sitemap'].includes( active_step ) && (test_site.is_built || site.is_built)" class="p-3">
										<btn_submit 
											label="Next step" 
											icon="check"  
											:icon_after="true" 
											:loading="loading"
										/>
									</div>
								</div>
								<button :disabled="loading" v-if="['client_name', 'url'].includes( active_step )" class="bg-transparent border-0 text-primary h-100 d-flex align-items-center justify-content-center px-3 border-start" style="width: 90px;">
									<span v-if="!loading" class="material-icons-outlined">send</span>
									<div v-else class="spinner-border spinner-border-sm" role="status">
										<span class="visually-hidden">Loading...</span>
									</div>
								</button>
							</form>
						</div>

					</div>

				</div>

				<div class="h-100 align-items-center justify-content-center overflow-hidden d-flex position-relative rounded-end flex-grow-1">

					<div class="w-100 h-100 position-relative py-4 overflow-auto">

						<div class="container h-100">

							<div class="row h-100 justify-content-center mb-4">

								<div class="col-12 " :class="active_step == 'sitemap' ? '' : 'col-xxl-11'">

									<div class="mb-4 d-flex align-items-center justify-content-between small">
										<div class="d-flex align-items-center flex-grow-1 me-4">
											<span class="me-4">
												Step {{ count }} of {{ total }}
											</span>

											<div class="progress flex-grow-1 mb-0 border">
												<div class="progress-bar" :style="'width: ' + percent + '%;'" :class="percent == 100 ? 'bg-success' : 'bg-info'">{{ percent }}%</div>
											</div>
										</div>

										<a v-if="site && site.id && !loading" href="#" @click="skip_wizard" class="ms-4">
											Skip setup wizard
										</a>
										<div v-else-if="site && site.id && loading" class="spinner-border spinner-border-sm ms-4" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>
									</div>

									<planning_page v-if="active_step == 'contact'" step="contact" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" />

									<planning_page v-if="active_step == 'social'" step="social" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" />

									<planning_page v-if="active_step == 'history'" step="history" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" />

									<planning_page v-if="active_step == 'mission'" step="mission" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" />

									<planning_page v-if="active_step == 'values'" step="values" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" :layout="2" />

									<planning_page v-if="active_step == 'audience'" step="audience" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" :layout="2" />

									<planning_page v-if="active_step == 'clients'" step="clients" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" :layout="2" />

									<planning_page v-if="active_step == 'team'" step="team" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" :layout="2" />

									<planning_page v-if="active_step == 'services'" step="services" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" :layout="2" />

									<planning_page v-if="active_step == 'usps'" step="usps" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" :layout="2" />

									<planning_page v-if="active_step == 'features'" step="features" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" :layout="2" />

									<planning_page v-if="active_step == 'plugins'" step="plugins" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" :layout="2" />

									<div v-if="active_step == 'sitemap' && site.is_built" class="planning-page mb-0 p-0" style="height: calc(100vh - 140px);" :style="( team && team.button_text) ? 'background: ' + team.button_text + '; color: ' + team.button_bg + ';' : ''">

										<img v-if="team && team.logo" :src="team.logo" class="position-absolute top-0 start-50 translate-middle-x" style="width: 200%; height: auto; filter: brightness(0) contrast(100%); opacity: 0.025;">

										<sitemap :key="'sitemap'" :hide_edit="true" />

									</div>

								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div v-if="site && site.id" class="modal fade" id="success" tabindex="-1" aria-labelledby="successLabel" aria-hidden="true">

				<confetti />

				<div class="modal-dialog modal-dialog-centered" style="z-index: 10">
					<div class="modal-content overflow-hidden">
						<div class="modal-body p-0">
							<div class="card mb-0 bg-primary rounded-0 text-white border-0 position-relative">

								<div class="card-body pe-4 p-0 d-flex align-items-center justify-content-start">

									<div class="me-2">
										<div class="mx-auto overflow-hidden" style="width: 250px; height: 250px;">
											<img src="@/assets/img/bella.png" class="img-fluid">
										</div>
									</div>

									<div>
										<h2 class="h3">All done!</h2>
										<p class="mb-0">When you're ready you can go to your site and view the full brief we just created.</p>
										
									</div>

								</div>
							</div>
						</div>
						<div class="modal-footer justify-content-between bg-primary" style="border-color: rgba( 255, 255, 255, 0.2 )">
							<btn_router_link 
								label="Go to site" 
								icon="east"  
								:icon_after="true" 
								:loading="loading"
								:route="{ name: 'site.planning', params: { site_id: site.id } }"
								@click="hide_modal"
								btn_class="d-inline-flex justify-content-between align-items-center btn btn-lg btn-outline-secondary w-100"
							/>
						</div>
					</div>
				</div>
			</div>

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import main_nav from '@/components/main_nav'
import support_link from '@/components/support_link'
import chat_message from '@/components/chat_message'
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'
import confetti from '@/components/confetti'
import btn_router_link from '@/components/btn_router_link'
import planning_page from '@/components/planning_page'
import sitemap from '@/components/sitemap'
import workspace_service from '@/services/workspace_service'
import site_service from '@/services/site_service'
import team_service from '@/services/team_service'

export default {
	name: 'site.add_wizard',

	components: {
		layout,
		main_nav,
		support_link,
		chat_message,
		form_control,
		btn_submit,
		btn_router_link,
		planning_page,
		confetti,
		sitemap
	},

	data() {
		return {
			loading: false,
			site_loading: false,
			workspace_loading: true,
			workspace: null,
			active_step: 'client_name',
			site: {
				name: null,
				url: null,
				locale: 'en_GB',
				settings: {}
			},
			include_countdown: false,
			client_name: null,
			url: null,
			error: {},
			total: 15,
			team: null,
			test_site: null
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		...mapGetters( 'locales', ['store_locales'] ),

		first_name()
		{
			if ( this.auth_user.name ) {
				const nameParts = this.auth_user.name.split(' ');
				return nameParts[0]
			}

			return ''
		},

		count()
		{
			var count = 1;

			if ( this.site && this.site.settings ) {
				if ( this.site.name ) {
					count++
				}
				if ( this.site.settings['url'] ) {
					count++
				}
				if ( this.site.settings['contact_complete'] ) {
					count++
				}
				if ( this.site.settings['social_complete'] ) {
					count++
				}
				if ( this.site.settings['history_complete'] ) {
					count++
				}
				if ( this.site.settings['mission_complete'] ) {
					count++
				}
				if ( this.site.settings['values_complete'] ) {
					count++
				}
				if ( this.site.settings['audience_complete'] ) {
					count++
				}
				if ( this.site.settings['clients_complete'] ) {
					count++
				}
				if ( this.site.settings['team_complete'] ) {
					count++
				}
				if ( this.site.settings['services_complete'] ) {
					count++
				}
				if ( this.site.settings['usps_complete'] ) {
					count++
				}
				if ( this.site.settings['features_complete'] ) {
					count++
				}
				if ( this.site.settings['plugins_complete'] ) {
					count++
				}
				if ( this.site.settings['sitemap_complete'] ) {
					count++
				}
			}

			return count;
		},

		percent()
		{
			

			return ( ( this.count / this.total ) * 100 ).toFixed( 0 );
		}

	},

	mounted()
	{
		this.get_workspace()

		if ( this.$route.params.site_id ) {
			this.get_site()
		} else{
			this.set_active()
		}

		this.navigate_scroll()

		this.get_team()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_team()
		{
			this.reset_error()

			team_service.show( this.auth_user.team_id ).then(( response ) => {
				this.team = response.data
			}).catch( ( error ) => {
				this.error = error
			})
		},

		set_active()
		{
			if ( this.site.name ) {
				this.active_step = 'url'
			}
			if ( this.site.settings['url'] ) {
				this.active_step = 'contact'
			}
			if ( this.site.settings['contact_complete'] ) {
				this.active_step = 'social'
			}
			if ( this.site.settings['social_complete'] ) {
				this.active_step = 'history'
			}
			if ( this.site.settings['history_complete'] ) {
				this.active_step = 'mission'
			}
			if ( this.site.settings['mission_complete'] ) {
				this.active_step = 'values'
			}
			if ( this.site.settings['values_complete'] ) {
				this.active_step = 'audience'
			}
			if ( this.site.settings['audience_complete'] ) {
				this.active_step = 'clients'
			}
			if ( this.site.settings['clients_complete'] ) {
				this.active_step = 'team'
			}
			if ( this.site.settings['team_complete'] ) {
				this.active_step = 'services'
			}
			if ( this.site.settings['services_complete'] ) {
				this.active_step = 'usps'
			}
			if ( this.site.settings['usps_complete'] ) {
				this.active_step = 'features'
			}
			if ( this.site.settings['features_complete'] ) {
				this.active_step = 'plugins'
			}
			if ( this.site.settings['plugins_complete'] ) {
				this.active_step = 'sitemap'
			}
			if ( this.site.settings['sitemap_complete'] || this.site.settings['skip_wizard_complete'] ) {
				this.active_step = 'complete'
				this.$router.push( { name: 'site.planning' } )
			}
		},

		hide_modal()
		{
			window.$( '#success' ).modal( 'hide' );
		},

		navigate_scroll()
		{
			this.$nextTick(() => {
				this.scrollToBottom();
				this.focusInput();
			});
		},

		submit()
		{
			this.reset_error()

			if ( this.active_step == 'client_name' ) {
				this.site.name = this.client_name
				this.active_step = 'url'
			} else if ( this.active_step == 'url' ) {
				this.site.settings['url'] = this.url
				this.create_site()
			} else if ( this.active_step == 'contact' ) {
				this.update( 'social' )
			} else if ( this.active_step == 'social' ) {
				this.update( 'history' )
			} else if ( this.active_step == 'history' ) {
				this.update( 'mission' )
			} else if ( this.active_step == 'mission' ) {
				this.update( 'values' )
			} else if ( this.active_step == 'values' ) {
				this.update( 'audience' )
			} else if ( this.active_step == 'audience' ) {
				this.update( 'clients' )
			} else if ( this.active_step == 'clients' ) {
				this.update( 'team' )
			} else if ( this.active_step == 'team' ) {
				this.update( 'services' )
			} else if ( this.active_step == 'services' ) {
				this.update( 'usps' )
			} else if ( this.active_step == 'usps' ) {
				this.update( 'features' )
			} else if ( this.active_step == 'features' ) {
				this.update( 'plugins' )
			} else if ( this.active_step == 'plugins' ) {
				this.update( 'sitemap' )
			} else if ( this.active_step == 'sitemap' ) {
				this.update( 'complete' )
			} 

			this.navigate_scroll()
		},

		async get_site()
		{
			this.site_loading = true

			this.reset_error()

			site_service.show( this.$route.params.workspace_id, this.$route.params.site_id ).then(( response ) => {
				this.site = response.data
				this.test_site = response.data
				this.set_active()

				if ( !this.site.is_built ) {
					setTimeout( () => {
						this.loop_site()
					}, 30000)
				}
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.site_loading = false

				this.navigate_scroll()
			})
		},

		async loop_site()
		{
			if ( !this.$route.params.site_id ) {
				setTimeout( () => {
					this.loop_site()
				}, 30000)
			}
			site_service.show( this.$route.params.workspace_id, this.$route.params.site_id ).then(( response ) => {
				this.test_site = response.data

				if ( !this.test_site.is_built ) {
					setTimeout( () => {
						this.loop_site()
					}, 30000)
				}
			})
		},

		async get_workspace()
		{
			this.reset_error()

			workspace_service.show( this.$route.params.workspace_id ).then(( response ) => {
				this.workspace = response.data
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.workspace_loading = false
			})
		},

		async create_site()
		{
			this.loading = true 

			this.include_countdown = true

			this.reset_error()

			site_service.store_wizard( this.$route.params.workspace_id, {
				name: this.client_name,
				url: this.url,
				locale: this.site.locale
			} ).then(( response ) => {
				this.site = response.data
				this.active_step = 'contact'
				this.$router.push( { name: 'site.add', params: { 'site_id': this.site.id } } )
				this.loop_site()
			}).catch( ( error ) => {
				this.error = error
				this.active_step = 'url'
			}).finally(() => {
				this.loading = false
				this.include_countdown = false
				this.navigate_scroll()
			})
		},

		async update( next_step )
		{
			this.loading = true 

			this.reset_error()

			this.site.question = this.active_step

			site_service.update_wizard( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {
				this.site = response.data
				this.active_step = next_step

				if ( this.active_step == 'complete' ) {
					this.$router.push( { name: 'site.planning' } )
				}
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
				this.navigate_scroll()
			})
		},

		async skip_wizard()
		{
			this.loading = true 

			this.reset_error()

			this.site.question = 'skip_wizard'

			site_service.update_wizard( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {
				this.site = response.data
				this.$router.push( { name: 'site.planning', params: { site_id: this.site.id } } )
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
				this.navigate_scroll()
			})
		},

		scrollToBottom() 
		{
			const container = this.$refs.scrollContainer;
			if (container) {
				container.scrollTop = container.scrollHeight;
			}
		},

		focusInput() 
		{
			window.$( '.setup-chat-actions input' ).focus();
		}
	}
}
</script>

<style>
	.setup-chat-actions .form-control,
	.setup-chat-actions .form-select {
		height: 100%;
		border: none;
		border-radius: 0;
		font-size: 1.2rem;
	}
</style>
