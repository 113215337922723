<template>
	
	<div class="confetti">
		<div class="confetti-piece"></div>
		<div class="confetti-piece"></div>
		<div class="confetti-piece"></div>
		<div class="confetti-piece"></div>
		<div class="confetti-piece"></div>
		<div class="confetti-piece"></div>
		<div class="confetti-piece"></div>
		<div class="confetti-piece"></div>
		<div class="confetti-piece"></div>
		<div class="confetti-piece"></div>
		<div class="confetti-piece"></div>
		<div class="confetti-piece"></div>
		<div class="confetti-piece"></div>
	</div>

</template>

<script>
export default {
	name: 'components.confetti',
	props: {}
}
</script>

<style>

</style>
